/** @jsx jsx */

import BackgroundImage from 'gatsby-background-image';
import ContactForm from '../components/ContactForm';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import Map from '../components/Map';
import { RichText } from 'prismic-reactjs';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import logo from '../components/logo.png';

export const query = graphql`
  query contact {
    prismic {
      contact_page(lang: "en-ca", uid: "contact-us") {
        description: page_content
        title: page_title
        hero_image
        hero_imageSharp {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default function Contact({
  data: {
    prismic: { contact_page },
  },
}) {
  const page = contact_page;
  // console.log('------- contact_page --------->', contact_page);
  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>Contact Dome GeoConsulting</title>
        <meta
          name="description"
          content="Contact us for business interest or any other questions."
        />
      </Helmet>
      <Layout>
        {page.hero_imageSharp && (
          <BackgroundImage
            className="bgr"
            sx={{ minHeight: [180, 250, 500] }}
            fluid={page.hero_imageSharp.childImageSharp.fluid}
          />
        )}
        <section sx={{ width: ['90%', '90%', '80%'], margin: 'auto' }}>
          <RichText render={page.title} />
          {page.description && <RichText render={page.description} />}{' '}
          <ContactForm />
        </section>
        <Map
          width="100%"
          geo={{ lat: 50.943011, lng: -113.975876 }}
          marker="Our office"
          logo={logo}
        />
      </Layout>
    </Fragment>
  );
}
